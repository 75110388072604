import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/git-cloning-branching",
  "date": "2014-12-07",
  "title": "GIT – CLONING & BRANCHING",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Imagine you are working in a team and one of your colleagues wants a copy of the your work, how do we start collaborating?"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`CLONING A REPOSITORY`}</h2>
    <p>{`Cloning into 'git-remote':`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git clone https://github.com/myurl/git-remote.git
`}</code></pre>
    <p>{`Cloning into 'git-local'`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git clone https://github.com/myurl/git-remote.git git-local
`}</code></pre>
    <p>{`Here `}<strong parentName="p">{`git-local`}</strong>{` is the local folder name. And `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://github.com/myurl/git-remote.git"
        }}>{`https://github.com/myurl/git-remote.git`}</a></strong>{` is the URL of the remote repository.`}</p>
    <h2>{`GIT CLONE`}</h2>
    <ul>
      <li parentName="ul">{`Downloads the entire repository into a new git-remote directory.`}</li>
      <li parentName="ul">{`Adds the ‘origin’ remote, pointing it to the clone URL.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git remote -v
origin https://github.com/myurl/git-remote.git (fetch)
origin https://github.com/myurl/git-remote.git (push)
`}</code></pre>
    <ul>
      <li parentName="ul">{`Checks out initial branch (likely master) - This sets HEAD.`}</li>
    </ul>
    <h2>{`BRANCHING OUT`}</h2>
    <p>{`Need to work on a feature that will take some time?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git branch cat
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git branch
cat
* master
`}</code></pre>
    <p>{`This will create a branch called `}<strong parentName="p">{`cat`}</strong>{`, which is created from master. But HEAD still is on master.`}</p>
    <h2>{`SWITCHING TO A BRANCH`}</h2>
    <p>{`Time to jump on that new 'cat' branch.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout cat
`}</code></pre>
    <p>{`Switched to branch 'cat'. Now the HEAD is on 'cat'`}</p>
    <h2>{`WORKING ON A BRANCH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`echo "Schrödinger" > cat.txt
git add cat.txt
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git commit -m "Create quantum cat."
[cat ab48a3f] Create quantum cat.
1 file changed, 1 insertion(+)
create mode 100644 cat.txt
`}</code></pre>
    <p>{`got commit will commit to the `}<strong parentName="p">{`cat`}</strong>{` branch.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`ls
README.txt cat.txt
`}</code></pre>
    <h2>{`BACK TO MASTER`}</h2>
    <p>{`Let's switch to branch 'master'`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout master
HEAD
`}</code></pre>
    <p>{`And we're back on master. (The HEAD is on 'master' now)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`ls
README.txt  
`}</code></pre>
    <p>{`As you can see 'cat.txt' is no gone.`}</p>
    <h2>{`BACK TO CAT`}</h2>
    <p>{`Now let's switch to branch 'cat'`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout cat
ls
README.txt cat.txt
`}</code></pre>
    <p>{`'cat' is still there.`}</p>
    <h2>{`TIME TO MERGE`}</h2>
    <p>{`Done with that feature branch? Time to merge it into 'master '.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout master
Switched to branch 'master'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`ls
README.txt
`}</code></pre>
    <p>{`Here we can't see 'cat' as expected.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git merge cat
Updating 1191ceb..ab48a3f
Fast-forward
cat.txt | 1 +
1 file changed, 1 insertion(+)
create mode 100644 cat.txt
`}</code></pre>
    <p>{`Merge brings one branch’s changes into another.`}</p>
    <h2>{`FAST-FORWARD TO THE FUTURE`}</h2>
    <p>{`Conditions for a fast-forward merge:`}</p>
    <p>{`The branch created should have something new on it and the master should NOT have any changes since the branch has been made to do a fast-forward merge.`}</p>
    <h2>{`BRANCH CLEAN UP`}</h2>
    <p>{`When you're done with a branch, you can safely remove it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git branch -d cat
Deleted branch cat (was 957dbff).
`}</code></pre>
    <h2>{`NON-FAST-FORWARD`}</h2>
    <p>{`In this case, the history looks slightly different, there is an additional commit emphasizing the merge. This commit even has the right message informing us about the merged branch. Let’s work on a new admin feature.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout -b admin
Switched to a new branch 'admin'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add admin/dashboard.html
git commit -m 'Add dashboard'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add admin/users.html
git commit -m 'Add user admin'
`}</code></pre>
    <h2>{`BUG FIXING ON MASTER`}</h2>
    <p>{`Time to put out the fire. We’ll get back to that admin branch later.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout master master
Switched to branch 'master'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git branch
admin
* master
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git pull
...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add store.rb
git commit -m 'Fix store bug'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add product.rb
git commit -m 'Fix product'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git push    
`}</code></pre>
    <h2>{`BACK TO OUR BRANCH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout admin
Switched to branch 'admin'
...
`}</code></pre>
    <p>{`When ready to bring in changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git checkout master
Switched to branch 'admin'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git merge admin
`}</code></pre>
    <h2>{`AND SUDDENLY...`}</h2>
    <p>{`Git uses Vi if no default editor is set to edit commit messages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`1 Merge branch 'admin'
2
3 # Please enter a commit message to explain why this merge is necessary,
4 # especially if it merges an updated upstream into a topic branch.
5 #
6 # Lines starting with '#' will be ignored, and an empty message aborts
7 # the commit.
:wq+ 
`}</code></pre>
    <p>{`Hit Enter to write (save) & quit`}</p>
    <p>{`Vi Commands`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`h left 
l right
j down 
k up 
ESC leave mode
i insert mode
:wq save & quit
:q! cancel & quit   
`}</code></pre>
    <h2>{`RECURSIVE MERGING`}</h2>
    <p>{`Git can’t fast-forward since changes were made in both branches.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`Merge made by the 'recursive' strategy.
0 files changed
create mode 100644 admin/dashboard.html
create mode 100644 admin/users.html 
`}</code></pre>
    <p>{`A commit was created to merge the two branches.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git log
A commit was created to merge the two branches.
commit 19f735c3556129279bb10a0d1447dc5aba1e1fa9
Merge: 5c9ed90 7980856
Author: Sean <sean@seanamarasinghe.com>
Date: Thu Dec 07 17:51:53 2014 -0400
Merge branch 'admin'    
`}</code></pre>
    <p>{`If you want to learn about more follow my other articles on Git here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/git-introduction/"
        }}>{`Git Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/git-stash/%22"
        }}>{`Git Stash`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      